<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

        </b-link>

        <b-card-title class="mb-1">
          Войти по коду 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Введите свой адрес электронной почты, и мы отправим вам код для входа.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="login"
                  :state="errors.length > 0 ? false:null"
                  :disabled="loading"
                  name="forgot-password-email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              type="submit"
              variant="primary"
              :disabled="loading"
              block
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-spinner
                  v-if="loading"
                  small
                  class="mr-50"
                />
                <span>Получить код</span>
              </div>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Вход
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup,
  BFormInput, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      login: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.loading = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt.forgotEndpoint({
            login: this.login,
          }).then(response => {
            if (response.data) {
              this.$router.push({
                name: 'verify-login',
              })
            }
            this.loading = false
          }).catch(error => {
            this.$refs.simpleRules.setErrors(error)
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
